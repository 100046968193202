<script lang="ts">
  import { createEventDispatcher } from "svelte";
  export let horsePower: number;

  const dispatch = createEventDispatcher<{ change: number }>();

  function handleInputChange(evt: Event) {
    const newValue = (evt.target as HTMLInputElement).valueAsNumber;
    horsePower = newValue;
    dispatch("change", newValue);
  }
</script>

<label
  for="horsePower-modal"
  class="stat cursor-pointer place-items-center lg:place-items-start hover:bg-primary hover:text-white"
>
  <div class="stat-title">Puissance fiscale</div>
  <div class="stat-value">
    {horsePower}
  </div>
  <div class="stat-desc">CV</div>
</label>

<input type="checkbox" id="horsePower-modal" class="modal-toggle" />

<label for="horsePower-modal" class="modal cursor-pointer">
  <div class="modal-box relative bg-white">
    <label
      for="horsePower-modal"
      class="btn btn-sm btn-circle absolute right-2 top-2">✕</label
    >
    <label for="">
      <h1 class="text-lg font-bold">Quelle est la puissance fiscale de votre véhicule ?</h1>
      <p class="py-4">
        Vous pouvez trouver cette information sur la carte grise de votre véhicule.
      </p>

      <div class="stat place-items-center">
        <div class="stat-title">Puissance fiscale</div>
        <div class="stat-value">
          {horsePower}
        </div>
        <div class="stat-desc">CV</div>
      </div>

      <input
        type="range"
        min={3}
        max={7}
        step={1}
        class="range range-primary"
        value={horsePower}
        on:input={handleInputChange}
      />
      <div class="w-full flex justify-between text-xs px-2">
        <span>≤ 3CV</span>
        <span>4CV</span>
        <span>5CV</span>
        <span>6CV</span>
        <span>≥ 7CV</span>
      </div>
    </label>
  </div>
</label>
