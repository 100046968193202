<script lang="ts">
  import { createEventDispatcher } from "svelte";
  export let distance: number;

  $: formattedDistance = new Intl.NumberFormat("fr-FR").format(distance);

  const dispatch = createEventDispatcher<{ change: number }>();

  function handleInputChange(evt: Event) {
    const newValue = (evt.target as HTMLInputElement).valueAsNumber;
    distance = newValue;
    dispatch("change", newValue);
  }
</script>

<label
  for="distance-modal"
  class="stat cursor-pointer place-items-center lg:place-items-start hover:bg-primary hover:text-white"
>
  <div class="stat-title">Distance parcourue</div>
  <div class="stat-value">
    {formattedDistance}
  </div>
  <div class="stat-desc">Km</div>
</label>

<input type="checkbox" id="distance-modal" class="modal-toggle" />

<label for="distance-modal" class="modal cursor-pointer">
  <div class="modal-box relative bg-white">
    <label
      for="distance-modal"
      class="btn btn-sm btn-circle absolute right-2 top-2">✕</label
    >
    <label for="">
      <h1 class="text-lg font-bold">Combien de kilomètre parcourez vous ?</h1>
      <p class="py-4">
        Indiqué le nombre de kilomètre que vous parcourez dans le cadre
        professionnel.
      </p>

      <div class="stat place-items-center">
        <div class="stat-title">Distance parcouru</div>
        <div class="stat-value">
          {formattedDistance}
        </div>
        <div class="stat-desc">Km</div>
      </div>

      <input
        type="range"
        min="0"
        max={30_000}
        class="range range-primary"
        value={distance}
        on:input={handleInputChange}
      />
    </label>
  </div>
</label>
