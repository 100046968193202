<script lang="ts">
  import { formatCurrency } from "../../../utils/formatter";
  import { computeIKValue } from "../../../utils/ik";

  export let distance: number;
  export let engine: "thermal" | "electric";
  export let horsePower: number;

  $: formattedIK = formatCurrency(computeIKValue(engine, horsePower, distance));
</script>

<div
  class="stat cursor-pointer place-items-center lg:place-items-start bg-primary text-white"
>
  <div class="stat-title">Indemnité kilométrique</div>
  <div class="DISTANCE_VALUE stat-value">{formattedIK}</div>
  <div class="stat-desc">Estimation basée sur le barème 2023</div>
</div>
