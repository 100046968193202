<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let engine: keyof typeof ENGINE_TYPE;

  const ENGINE_TYPE = { thermal: "Thermique", electric: "Électrique" };

  $: formattedEngine = ENGINE_TYPE[engine];

  const dispatch = createEventDispatcher<{
    change: typeof engine;
  }>();

  function handleInputChange(evt: Event) {
    const newValue = (evt.target as HTMLSelectElement).value as typeof engine;
    engine = newValue;
    dispatch("change", newValue);
  }
</script>

<label
  for="engine-modal"
  class="stat cursor-pointer place-items-center lg:place-items-start hover:bg-primary hover:text-white"
>
  <div class="stat-title">Type de véhicule</div>
  <div class="stat-value">
    {formattedEngine}
  </div>
  <div class="stat-desc">Thermique ou Électrique</div>
</label>

<input type="checkbox" id="engine-modal" class="modal-toggle" />

<label for="engine-modal" class="modal cursor-pointer">
  <div class="modal-box relative bg-white">
    <label
      for="engine-modal"
      class="btn btn-sm btn-circle absolute right-2 top-2">✕</label
    >
    <label for="">
      <h1 class="text-lg font-bold">Dans quel véhicule roulez vous ?</h1>
      <p class="py-4">
        Les voitures <strong>essences</strong>, <strong>diesel</strong> et <strong>hybrid</strong> sont considérées comme <strong>thermique</strong>.
      </p>

      <div class="stat place-items-center">
        <div class="stat-title">Type de véhicule</div>
        <div class="stat-value">
          {formattedEngine}
        </div>
        <div class="stat-desc">Thermique ou Électriques</div>
      </div>
    </label>

    <select class="select select-bordered w-full" on:input={handleInputChange}>
      <option disabled selected value="thermal"
        >Choisissez un type de moteur</option
      >

      {#each Object.entries(ENGINE_TYPE) as [key, value]}
        <option value={key}>{value}</option>
      {/each}
    </select>
  </div>
</label>
