type DistanceLowerBoundValue = 0 | 5000 | 20000;
type TaxableHorsePowerValue = 3 | 4 | 5 | 6 | 7;

export function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
  }

/**
 * Takes a taxable horse power and returns its matching value in the IK coefficients table.
 */
function matchingTaxableHorsePowerValue(value: number): TaxableHorsePowerValue {
    return clamp(Math.round(value), 3, 7) as TaxableHorsePowerValue;
  }

/**
 * Takes a distance and returns its matching lower bound in the IK coefficients table.
 */
function matchingDistanceLowerBound(distance: number): DistanceLowerBoundValue {
  let bound: DistanceLowerBoundValue;
  if (distance > 20000) {
    bound = 20000;
  } else if (distance > 5000) {
    bound = 5000;
  } else {
    bound = 0;
  }
  return bound;
}

// TODO handle date to use the correct coefficients, as they can change over time.
// See https://www.service-public.fr/particuliers/actualites/A14686
const IK_COEFFICIENTS: {
  [taxableHorsepower in TaxableHorsePowerValue]: {
    [lowerBound in DistanceLowerBoundValue]: { slope: number; offset: number };
  };
} = {
  3: { 0: { slope: 0.529, offset: 0 }, 5000: { slope: 0.316, offset: 1065 }, 20000: { slope: 0.37, offset: 0 } },
  4: { 0: { slope: 0.606, offset: 0 }, 5000: { slope: 0.340, offset: 1330 }, 20000: { slope: 0.407, offset: 0 } },
  5: { 0: { slope: 0.636, offset: 0 }, 5000: { slope: 0.357, offset: 1395 }, 20000: { slope: 0.427, offset: 0 } },
  6: { 0: { slope: 0.665, offset: 0 }, 5000: { slope: 0.374, offset: 1457 }, 20000: { slope: 0.447, offset: 0 } },
  7: { 0: { slope: 0.697, offset: 0 }, 5000: { slope: 0.394, offset: 1515 }, 20000: { slope: 0.47, offset: 0 } },
};

/**
 * Computes the IK value in euros, from a vehicle and a distance in kilometers.
 */
export function computeIKValue(engine: 'thermal' | 'electric', horsePower: number, distance: number) {
  const bound = matchingDistanceLowerBound(distance);
  const taxableHorsePower = matchingTaxableHorsePowerValue(horsePower);
  const { slope, offset } = IK_COEFFICIENTS[taxableHorsePower][bound];

  const result = slope * distance + offset;
  if (engine === 'electric') {
    return result * 1.2;
  } else {
    return result;
  }
}
