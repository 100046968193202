<script lang="ts">
  import DistanceSection from "./DistanceSection.svelte";
  import EngineSection from "./EngineSection.svelte";
  import EstimationSection from "./EstimationSection.svelte";
  import HorsePowerSection from "./HorsePowerSection.svelte";

  let distance = 3_000;
  let engine: "thermal" | "electric" = "thermal";
  let horsePower = 4;

  function handleDistanceChange(change: CustomEvent<number>) {
    distance = change.detail;
  }

  function handleEngineChange(change: CustomEvent<"thermal" | "electric">) {
    engine = change.detail;
  }

  function handleHorsePowerChange(change: CustomEvent<number>) {
    horsePower = change.detail;
  }
</script>

<div class="flex flex-col justify-center">
  <div
    class="stats bg-white stats-vertical lg:w-auto lg:stats-horizontal shadow"
  >
    <DistanceSection {distance} on:change={handleDistanceChange} />
    <EngineSection {engine} on:change={handleEngineChange} />
    <HorsePowerSection {horsePower} on:change={handleHorsePowerChange} />
    <EstimationSection {distance} {horsePower} {engine} />
  </div>
  
  <p class="mt-2 w-full prose text-sm text-neutral-content opacity-70 text-center max-w-none break-words">Cliquez sur les valeurs pour les adapter à votre situation</p>
</div>
